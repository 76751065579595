import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-948b00e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "component-wrapper"
}
const _hoisted_2 = { class: "header-cont" }
const _hoisted_3 = { class: "title-cont" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "name" }
const _hoisted_7 = { class: "rating-label our-rating" }
const _hoisted_8 = { class: "value" }
const _hoisted_9 = { class: "hero-cont" }
const _hoisted_10 = ["title"]
const _hoisted_11 = { class: "card-body" }
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.diceJobs)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.diceJobs, (job, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: "card-component"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("a", {
                  href: job.company_link,
                  class: "company",
                  target: "_blank",
                  rel: "noopener noreferrer"
                }, [
                  _createElementVNode("img", {
                    src: _ctx.handleCompanyLogo(job.company_logo),
                    class: "logo",
                    alt: "company logo"
                  }, null, 8, _hoisted_5),
                  _createElementVNode("h5", _hoisted_6, _toDisplayString(job.company), 1)
                ], 8, _hoisted_4)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h5", _hoisted_8, _toDisplayString(job.date_posted), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h3", {
                class: "title",
                title: job.title
              }, _toDisplayString(job.title), 9, _hoisted_10)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("p", null, _toDisplayString(_ctx.formatDescription(job.description_list)), 1)
            ])
          ]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("p", _hoisted_12, "Loading cards..."))
}